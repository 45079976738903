//
// Pre-existing Classes to be overwritten
// 
.font-weight-normal {
	font-family: "proxima_novalight" !important;
	font-weight: normal;
}
.actions button.dropdown-toggle, .actions .btn-outline-primary:not(
	table .actions .dropdown-menu a, .card .actions .dropdown-menu a
) {
	border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
// Borders
.border {
	border: 1px solid rgb(224,224,224) !important;
}
.border-top {
	border-top: 1px solid rgb(224,224,224) !important;
}
.border-right {
	border-right: 1px solid rgb(224,224,224) !important;
}
.border-left {
	border-left: 1px solid rgb(224,224,224) !important;
}
.border-bottom {
	border-bottom: 1px solid rgb(224,224,224) !important;
}

// Multi selects
#navdrawerSharebar .multiselect__tags {
	min-height: 40px;
	input {
		line-height: inherit;
		width: auto;
	}	
}
.multiselect {
	min-height: 36px !important;
}
.multiselect__tags {
	border-radius: 2px !important;
}
.multiselect__placeholder {
	margin-bottom: 0 !important;
	padding-top: 0 !important;
}
.multiselect__single {
	color: rgba(0,0,0,0.25) !important;
}
.multiple-select {   
	border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0.25rem;
    margin: .5rem 0rem;
}
.select2-container--material .select2-selection {
	border-color: rgba(0, 0, 0, 0.42) !important;
}
// Alternate shadows
.card, .list-group:not(.list-group-flush), .multiselect__content-wrapper,
.card .admin-up .fa, .card .admin-up .material-icons, .shadow, .stepper-vert {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 7px !important;	
}
.card .list-group {
	box-shadow: none !important;
	.list-group-item {
		box-shadow: none !important;
		border-bottom: 1px solid #e1e1e1;
	}
}
.list-group-flush .list-group-item {
	border-top: none !important
}
.btn:not(
	.btn-link, .btn-group > .btn, .btn-group-vertical > .btn, .btn-flat, .dropdown-menu .btn,
	.btn.handle, .navdrawer .btn, .navbar .btn, .btn-outline-primary
), .btn.btn-light, .btn.btn-primary, .btn.btn-danger, .multiselect__tags:not(.form-filter .multiselect__tags) {
	box-shadow: /*0 0 7px 0 rgb(0,0,0,0.1),*/ inset 0 0 1px rgba(0,0,0,0.5) !important;
	border: none !important;
}
.btn-group {
	box-shadow: none;
}
/* Alternate breadcrumbs */
.breadcrumbs ol {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.breadcrumb-item + .breadcrumb-item::before {
    color: rgba(0, 0, 0, 0.54);
    content: ">";
    display: inline-block;
    margin-right: 0.5rem;
}
ol.breadcrumb {
	min-height: 3rem;
	padding: 0;
}
// Tabs
.nav-tabs .nav-link {
	text-transform: none;
}

// Whatever
.input-group > .custom-file, 
.input-group > .custom-select, 
.input-group > .floating-label {
	width: 100%;
}
.btn.btn-warning {
	color: white !important;
}
.admin .card-header {
	background-color: #fff;
}
.btn-group > .btn[class*='btn-outline'], .btn-group-vertical > .btn[class*='btn-outline'] {
	opacity: 1 !important;
}