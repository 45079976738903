@import 'base', 'overwrites';

/* fonts */
/* Add Material font (Roboto) and Material icon as needed */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i|Roboto+Mono:300,400,700|Roboto+Slab:300,400,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$blue: #00afff;
$orange: #ee7e1c;
$gradienttop: linear-gradient(to top, #ee7a01, #e84e10);
$gradientbot: linear-gradient(to bottom, #ee7a01, #e84e10);
/*
	Misc stuff that doesn't fit anywhere else
*/
h1, h2, h3, h4, h5, h6, .btn {
	font-family: "Roboto" !important;
}
html, body {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	line-height: 1.8;
	font-size: 1rem;
}
p {
	line-height: 1.8;
	font-size: 1rem;
}
body {
	background-color: #edf2f5;
}
// Nav
@media (min-width: 992px) {
	.navdrawer-permanent-lg ~ main {
		margin-left: 14.5rem;
	}
}
.navdrawer-content, .navdrawer-permanent-lg {
	width: 14.5rem;
}
// Table & Actions
.card div.actions .btn-sm {
	font-size: 0.8125rem;
	padding: 0.25rem;
}
table .actions .btn-group,
.card .actions .btn-group {
	box-shadow: none;
}

table .actions .dropdown-menu a,
.card .actions .dropdown-menu a {
	margin-bottom: 0px;
	min-width: inherit;
	border: none;
	text-align: left;
	display: block;
	width: 100%;
}
// table .actions a[class*="btn-outline"],
// table .actions button.dropdown-toggle,
// .card .actions a[class*="btn-outline"],
// .card .actions button.dropdown-toggle {
//     margin-bottom: 0px;
//     border: 1px solid #ccc;
// }

.dropdown-toggle::after {
	margin-left: 0 !important;
}
table {
	thead {
		a {
			color: rgba(0, 0, 0, 0.54) !important;
			font-weight: normal;
		}
	}
	th, td {
		vertical-align: middle !important;
	}
	td.actions {
		max-width: 350px;
		width: fit-content;
		text-align: right;
		.btn-sm {
			padding: 5px;
			font-size: 0.8125rem;
			padding: 0.25rem;
		}
		a:not(.btn), button.dropdown-toggle {
			margin-bottom: 0px;
			border: 1px solid #ccc;
		}
		.btn-group {
			box-shadow: none;
		}
		.dropdown-menu a {
			margin-bottom: 0px;
			min-width: inherit;
			border: none;
			text-align: left;
			display: block;
			width: 100%;
		}
	}
}
.actions {
	a:not(.btn), button.dropdown-toggle {
		margin-bottom: 0px;
		border: 1px solid #ccc;
	}
	.btn-sm {
		padding: 5px;
	}
	.btn-group {
		box-shadow: none;
	}
	.dropdown-menu a {
		margin-bottom: 0px;
		min-width: inherit;
		border: none;
		text-align: left;
		display: block;
		width: 100%;
	}
}

@media (min-width: 1500px) {
	.col-xxl-1 {
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-xxl-2 {
		flex: 0 0 16.666666%;
		max-width: 16.666666%;
	}
	.col-xxl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xxl-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-xxl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xxl-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
small.text-small {
	line-height: 20px;
}
.td-radio > div {
	margin-bottom: 0rem;
}
.td-true {
	$g: rgba(168, 226, 152, 0.45);
	background-color: rgb(233, 255, 227);
	border-left: 1px solid $g;
	border-right: 1px solid $g;
}
.td-false {
	$r: rgba(228, 161, 161, 0.45);
	background-color: rgb(255, 239, 239);
	border-left: 1px solid $r;
	border-right: 1px solid $r;
}
.td-invalid {
	background-color: #f3f3f3;
	border-left: 1px solid #e1e1e1;
	border-right: 1px solid #e1e1e1;
}
.form-group > label {
	font-size: 1rem;
}
.custom-checkbox .custom-control-label {
	&::after {
		line-height: 1.40 !important;
	}
	&::before {
		top: 4px;
	}
}
.focused {
	.form-control::placeholder {
		color: rgba(0, 0, 0, 0.38) !important;
		opacity: 1 !important;
	}
	label {
		top: 0 !important;
		transform: scale(0.75) !important;
	}
}
.no-label .form-group {
	margin-bottom: 0;
	.floating-label {
		padding-top: 0;
	}
}
.flex-basis-66 {
	flex-basis: 66%;
}
.table .thead-lighter td, .table .thead-lighter th {
	background-color: #f8f8f8;
	color: rgba(0, 0, 0, 0.54);
}
.opacity-07 {
	opacity: 0.7;
}
.object-fit-cover, .of-cover {
	object-fit: cover;
}
.title {
	h1, h2, h3, h4, h5, h6 {
		color: $alt-blue;
		+ span {
			margin-top: -0.4rem;
			line-height: 18px;
		}
	}
	&.compact {
		h1, h2, h3, h4, h5, h6 {
			line-height: 28px;
			+ span {
				line-height: 28px;
			}
		}
	}
	&.no-color {
		h1, h2, h3, h4, h5, h6 {
			color: inherit;
		}
	}
	h6 + span {
		margin-top: -0.19rem;
	}
	&.no-sub {
		h1, h2, h3, h4, h5, h6, p {
			line-height: 50px;
		}
	}
}
.row-gap {
	row-gap: 1rem;
}
.column-gap {
	column-gap: 1rem;
}
.deactivated {
	filter: grayscale(100%);
	opacity: 65%;
}
.px-20px {
	padding-left: 20px !important;
	padding-right: 20px !important;
}
.py-20px {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}
.p-20px {
	padding: 20px !important;
}
.gap {
	gap: 1rem;
}
.mb-0-next {
	> * {
		margin-bottom: 0 !important;
	}
	small.form-text.text-muted {
		display: none;
	}
	.form-group.input.textarea {
		.floating-label label {
			top: 1rem !important;
		}
		.floating-label.has-value label, .floating-label.is-focused label {
			transform: initial;
		}
	}
}
.mt-0-cascade * {
	margin-top: 0 !important;
}
.mb-0-cascade * {
	margin-bottom: 0 !important;
}
.text-align-left {
	text-align: left !important;
}
.text-align-right {
	text-align: right !important;
}
td.collapse.show {
	display: table-cell;
}
tr.border-0 {
	td, th {
		border: 0;
	}
}
hr.card-divider {
	border-top: 1px solid rgb(224, 224, 224);
	margin: 1rem -1rem;
}
.lh-50 {
	line-height: 50px !important;
}
.lh-0 {
	line-height: 0 !important;
}
.clickable {
	cursor: pointer;
}
.none {
	display: inline !important;
}
option:disabled {
	color: rgba(0, 0, 0, 0.35);
}
input[type="radio"] + label, input[type="checkbox"] + label {
	user-select: none;
}
.stepper-text {
	width: 85%;
}
.text-shadow {
	text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}
.btn-warning, .btn-info, .btn-success, .btn-danger {
	text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}
header {
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}
thead tr th.th-sm {
	padding-top: 0.919643rem;
	padding-bottom: 0.919643rem;
	height: initial;
}
.card-img-pricetag {
	opacity: 0.6;
	position: absolute;
	top: 0;
	right: 0;
	padding: 0 5px;
	background-color: #fff;
	border-bottom-left-radius: 2px;
	box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
	border-left: 1px solid rgba(0, 0, 0, 0.12);
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
// Discount inputs
.discount-input + label {
	overflow: visible !important;
	&::after {
		content: "%";
		position: absolute;
		top: 35px;
		font-size: 1.7rem;
		left: 70px;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.87);
	}
}
.discount-input:disabled + label {
	&::after {
		color: rgba(0, 0, 0, 0.38);
	}
	&::before {
		content: "";
		position: absolute;
		top: 56px;
		left: 0;
		background-color: rgba(0, 0, 0, 0.38);
		width: 66px;
		height: 1px;
	}
}
.no-label .discount-input + label::after {
	top: 5px;
}
// Badge
h1 + .badge-alt {
	margin-bottom: 11px;
	margin-left: 15px;
	&.badge-warning, &.badge-danger, &.badge-supreme, &.badge-success {
		flex-basis: initial
	}
}
.card .card-img-overlay .badge-alt {
	&.badge-warning, &.badge-danger, &.badge-supreme {
		position: absolute;
		top: -72px;
		left: 0;
		right: 0;
		display: block;
		width: 85%;
		margin: 0 auto;
	}
	&.badge-success {
		flex-basis: initial;
		text-align: initial;
		padding: 0;
	}
}
.badge-alt {
	display: block;
	min-height: 13px;
	min-width: 13px;
	border-radius: 50px;
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.25), inset 0 0 1.5px rgba(0, 0, 0, 0.25);
	color: #fff;
	text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
	&.badge-warning, &.badge-danger, &.badge-supreme, &.badge-success {
		padding: 0 10px;
		align-self: center;
		flex-basis: 100%;
		text-align: center;
	}
	&.badge-success {
		background-color: #75d178;
	}
	&.badge-warning {
		background-color: #ffbb54;
	}
	&.badge-danger {
		background-color: #f16e65;
	}
	&.badge-supreme {
		background-color: #ea96ff;
	}
}
.wide-multiselect {
	max-width: 275px;
	width: 100%;
	.form-group, .vue-form-select {
		width: 100%;
	}
	.multiselect__tags {
		min-height: 0;
	}
	.multiselect__placeholder {
		margin-bottom: 0 !important;
		min-width: 215px;
		font-size: 16px;
		padding: 0 0 0 5px;
		color: rgba(0, 0, 0, 0.38);
		line-height: initial;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
.filter-form {
	.btn.btn-light {
		height: 36px;
		margin-bottom: 0;
	}
}
.text-truncate {
	user-select: none;
	&:hover {
		background: #fff;
		overflow: visible;
		z-index: 1;
		position: relative;
	}
}
.admin .iportfolio-navdrawer-body {
	background-color: transparent;
	color: rgba(0, 0, 0, 0.02);
}
.admin .iportfolio-navdrawer-gradient {
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='800' preserveAspectRatio='none' viewBox='0 0 1440 800'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1031%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c472 C 96%2c438.6 288%2c284.2 480%2c305 C 672%2c325.8 768%2c635.2 960%2c576 C 1152%2c516.8 1344%2c122.4 1440%2c9L1440 800L0 800z' fill='rgba(0%2c 0%2c 0%2c 0.03)'%3e%3c/path%3e%3cpath d='M 0%2c704 C 57.6%2c637 172.8%2c415.8 288%2c369 C 403.2%2c322.2 460.8%2c510.4 576%2c470 C 691.2%2c429.6 748.8%2c133.2 864%2c167 C 979.2%2c200.8 1036.8%2c627.2 1152%2c639 C 1267.2%2c650.8 1382.4%2c308.6 1440%2c226L1440 800L0 800z' fill='rgba(0%2c 0%2c 0%2c 0.02)'%3e%3c/path%3e%3cpath d='M 0%2c105 C 96%2c223.2 288%2c683 480%2c696 C 672%2c709 768%2c209.4 960%2c170 C 1152%2c130.6 1344%2c433.2 1440%2c499L1440 800L0 800z' fill='rgba(0%2c 0%2c 0%2c 0.02)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1031'%3e%3crect width='1440' height='800' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: cover;
	background-color: transparent;
}
.admin .navdrawer .navdrawer-footer {
	background-color: rgba(0, 0, 0, 0.2);
}
.admin-background {
	background-blend-mode: overlay;
	background-color: #edf2f5;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	background-size: 50%;
	background-repeat: repeat;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
}

.login {
	background: var(--amber)!important;
	background-repeat: no-repeat;
	background-size: cover;	
	color: rgba(0, 0, 0, 0.02);
	position: relative;
	&::after {
		content: "";
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='800' preserveAspectRatio='none' viewBox='0 0 1440 800'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1031%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c472 C 96%2c438.6 288%2c284.2 480%2c305 C 672%2c325.8 768%2c635.2 960%2c576 C 1152%2c516.8 1344%2c122.4 1440%2c9L1440 800L0 800z' fill='rgba(0%2c 0%2c 0%2c 0.03)'%3e%3c/path%3e%3cpath d='M 0%2c704 C 57.6%2c637 172.8%2c415.8 288%2c369 C 403.2%2c322.2 460.8%2c510.4 576%2c470 C 691.2%2c429.6 748.8%2c133.2 864%2c167 C 979.2%2c200.8 1036.8%2c627.2 1152%2c639 C 1267.2%2c650.8 1382.4%2c308.6 1440%2c226L1440 800L0 800z' fill='rgba(0%2c 0%2c 0%2c 0.02)'%3e%3c/path%3e%3cpath d='M 0%2c105 C 96%2c223.2 288%2c683 480%2c696 C 672%2c709 768%2c209.4 960%2c170 C 1152%2c130.6 1344%2c433.2 1440%2c499L1440 800L0 800z' fill='rgba(0%2c 0%2c 0%2c 0.02)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1031'%3e%3crect width='1440' height='800' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-size: cover;
		background-color: transparent;
	}
	.col-12 {
		z-index: 1;
	}
}
.page-login {
	background-color: $orange;
	/* z-index: -1; */
}
div.modulecontent .dropdown .btn {
	box-shadow: none !important;
}
.loader {
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: auto;
	height: auto;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.75);
}
.loader .align-middle {
	margin: auto;
	transform: translateY(-50%);
	top: 50%;
}
.statistics {
	th, td {
		font-size: 1rem !important;
	}
}
span[style*="color: #ffffff;"] {
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
}
.bg-orange-transparent {
	background-color: #ee7e1cb3;
	border-radius: 12px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.img-shadow {
	border-radius: 12px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.img-circle {
	clip-path: circle();
}
.img-circle-shadow {
	filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15));
}

.video {
	video {

		width: 100%;
		max-width: 500px;
	}
}
a.badge.badge-light {
	background-color: #fff;
	color: #ff4081;
}
.dz-filesize {
	position: absolute;
	bottom: 0;
	right: 0;
	&.dz-danger {
		background-color: #d10e00b3;
	}
	&.dz-warning {
		background-color: #d18f00b3;
	}
	&.dz-info {
		background-color: #00a9d1b3;
	}
	strong {
		text-align: right;
		padding: 0 6px;
		color: #fff;
	}
}
#blob-generator {
	border-radius: 12px;
	width: 100%;
	max-width: 350px;
	height: 100%;
	max-height: 350px;
	margin: 0 auto;
}
.inset-shadow {
	box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.15) !important;
	border-radius: 2px;
}
.spacer, .mspacer, .dspacer {
	background-color: #ea00ff66;
	position: relative;
	padding: 10px;
	margin: 5px;
	display: block !important;
	&::before {
		content: "Invisible spacer";
		position: absolute;
		top: 5px;
		left: 5px;
	}
}
.mspacer {
	background-color: #9900ff3d;
	&::before {
		content: "Invisible mobile spacer";
	}
}
.dspacer {
	background-color: #5900ff3d;
	&::before {
		content: "Invisible desktop spacer";
	}
}

#particles-2 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	pointer-events: none;
}
#particles-1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	pointer-events: none;
}

// I don't even know why some inputs are hidden to begin with...
.force-show-input .custom-control-input {
	opacity: 1 !important;
	z-index: 1 !important;
}

// Prevent accidental clicking out
.modal-content::before {
	content: "";
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	background-color: transparent;
	display: block;
	z-index: -1;
}

#preview-options-background-video.admin-preview-filemanager-image {
	background-image: none !important;
	width: 250px;
	height: auto;
	img, .remove-admin-preview-filemanager-image {
		display: none;
	}
}